const initResultsPanel = () => {
	const stickyElement = document.querySelector( '.calculator-block-results-wrapper' );
	if ( !stickyElement ) return;

	let isExpanded = false;
	let initialBottom = -( stickyElement.offsetHeight - 280 );

	// Function to apply initial styles for viewports 900px or smaller
	function applyStickyStyles() {
		stickyElement.style.bottom = isExpanded ? '0px' : `${initialBottom}px`;
	}

	// Function to remove applied styles for viewports above 900px
	function removeStickyStyles() {
		stickyElement.style.removeProperty( 'bottom' );
		isExpanded = false; // Reset expansion state
	}

	// Toggle visibility on click
	stickyElement.addEventListener( 'click', () => {
		if ( window.matchMedia( '(max-width: 900px)' ).matches ) { // Only toggle if within the responsive breakpoint
			isExpanded = !isExpanded;
			stickyElement.classList.toggle( 'expanded' );
			applyStickyStyles();
		}
	} );

	// Check viewport width and apply/remove styles accordingly
	function checkViewport() {
		if ( window.matchMedia( '(max-width: 900px)' ).matches ) {
			applyStickyStyles();
			stickyElement.classList.add( 'loaded' );
		} else {
			removeStickyStyles();
		}
	}

	// Listen for window resize events
	window.addEventListener( 'resize', () => {
		// Recalculate initialBottom in case element height changes (e.g., content changes or other responsive adjustments)
		initialBottom = -( stickyElement.offsetHeight - 280 );
		checkViewport();
	} );

	// Initial check
	checkViewport();
};

export default initResultsPanel;
