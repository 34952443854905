import initResultsPanel from './results-panel';

const inView = (element, callback, options = {}) => {
	const defaultOptions = {
		root: null,
		rootMargin: '0px',
		threshold: 0.5,
	};

	const mergedOptions = { ...defaultOptions, ...options };

	const handleIntersection = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				callback(true);
			} else {
				callback(false);
			}
		});
	};

	const observer = new IntersectionObserver(handleIntersection, mergedOptions);
	observer.observe(element);
};

const calculatorImporter = () => {
	const imports = [
		{
			path: 'coverage',
			test: document.querySelector('.calculator-block--coverage'),
		},
		{
			path: 'premium',
			test: document.querySelector('.calculator-block--premium'),
		},
		{
			path: 'waepa-fegli',
			test: document.querySelector('.calculator-block--waepa-fegli'),
		},
		{
			path: 'associate-member',
			test: document.querySelector('.calculator-block--associate-member'),
		},
		{
			path: 'guaranteed-issue',
			test: document.querySelector('.calculator-block--guaranteed-issue'),
		},
		{
			path: 'rates-stdi',
			test: document.querySelector('.calculator-block--stdi-rates'),
		},
		{
			path: 'stdi',
			test: document.querySelector('.calculator-block--stdi'),
		},
		{
			path: 'rates-chart',
			test: document.querySelector('.calculator-block--rates'),
		},
	];

	imports.forEach(({ path, test }) => {
		if (test) {
			inView(test, (isVisible) => {
				if (isVisible) {
					import(
						/* webpackChunkName: "[request]" */
						`./${path}.js`
					).then(setTimeout(()=>{
						initResultsPanel();
					}, 1000));
				}
			});
		}
	});
};

calculatorImporter();
