function setScrollbarWidthVariable() {
	// Create a temporary element to measure the scrollbar width
	const measureBox = document.createElement( 'div' );
	measureBox.style.overflow = 'scroll';
	document.body.appendChild( measureBox );
	const scrollbarWidth = measureBox.offsetWidth - measureBox.clientWidth;
	document.body.removeChild( measureBox );

	// Set the --scrollbar-width variable on the :root element
	document.documentElement.style.setProperty( '--scrollbar-width', `${scrollbarWidth}px` );
}

// Call the function to set the variable
setScrollbarWidthVariable();
