document.addEventListener('DOMContentLoaded', () => {
	// Query the video and button elements.
	const videoBlock = document.querySelector('.wp-block-wdg-video-cta.wdg-video-cta');
	if (!videoBlock) return; // Exit if the video block is not found.

	const video = videoBlock.querySelector('video');
	const soundButton = videoBlock.querySelector('.wdg-video-cta__buttons--sound');
	const playButton = videoBlock.querySelector('.wdg-video-cta__buttons--play');

	if(playButton.textContent.indexOf('Pause Video') > -1)
		playButton.textContent = 'Pause Video \u23F8';
	else
		playButton.textContent = 'Play Video \u25B6';

	// Toggle play/pause on clicking the play button.
	playButton.addEventListener('click', function () {
		if (video.paused || video.ended) {
			video.play();
			this.textContent = 'Pause Video \u23F8'; // Change button text to 'Pause'
		} else {
			video.pause();
			this.textContent = 'Play Video \u25B6'; // Change button text to 'Play'
		}
	});

	// Toggle mute/unmute on clicking the sound button.
	soundButton.addEventListener('click', function () {
		video.muted = !video.muted;
		this.textContent = video.muted ? 'Unmute' : 'Mute'; // Change button text accordingly

		if (video.muted) {
			this.classList.remove('not-muted');
		} else {
			this.classList.add('not-muted');
		}
	});
});
