export class Tabs {
	constructor( node, config ) {
		this.node = node;
		this.config = {
			tabList: '[role="tablist"]',
			tabs: '[role="tab"]',
			tabPanels: '[role="tabpanel"]',
			navClass: 'tabs__nav',
			navOpenClass: 'is-open',
			navItemClass: 'tabs__nav-item',
			navItemActiveClass: 'is-active',
			currentItemClass: 'tabs__nav-current',
			...config,
		};

		if ( ! this.node ) {
			return;
		}

		this.tabMap = {};

		this.tabList = this.node.querySelector( this.config.tabList );
		this.tabs = Array.from( this.tabList.querySelectorAll( this.config.tabs ) );
		this.tabPanels = Array.from( this.node.querySelectorAll( this.config.tabPanels ) );
		this.orientation = this.tabList.getAttribute( 'aria-orientation' );

		this.nav = this.node.querySelector( `.${ this.config.navClass }` );
		this.currentItem = this.node.querySelector( `.${ this.config.currentItemClass }` );

		if ( this.currentItem && this.nav ) {
			this.currentItemText = this.currentItem.querySelector( 'span' );

			this.currentItem.addEventListener( 'click', (event) => {
				this.nav.classList.toggle(this.config.navOpenClass);
			} );
		}

		// Create an object where the where the keys are the hash
		// and the values are the corresponding HTML elements
		// { '#1': button#tab-1 }
		this.tabs.forEach( ( tab ) => {
			const hash = this.getTabHashFromId( tab.id );
			this.tabMap[ hash ] = tab;
		} );

		this.init();
	}

	init() {
		// Hide all tab panels except the first
		this.setFirstTab();

		// Enable arrow navigation between tabs in the tab list
		this.tabList.addEventListener( 'keydown', ( e ) => this.handleKeydown( e ) );

		// Add a click event handler to each tab
		this.tabs.forEach( ( tab ) => {
			tab.addEventListener( 'click', ( e ) => { this.changeTabs( e ) 
				// Update the current item text on mobile

				if ( this.currentItemText && e.isTrusted) {
					this.nav.classList.toggle(this.config.navOpenClass);
				}} 
			);
		} );

		window.addEventListener( 'load', () => this.handleHash(), false );
		window.addEventListener( 'hashchange', () => this.handleHash(), false );
	}

	setFirstTab() {
		if ( this.tabs.length > 0 ) {
			this.tabs[ 0 ].classList.add( this.config.navItemActiveClass );
			this.tabs[ 0 ].setAttribute( 'aria-selected', true );
			this.tabs[ 0 ].setAttribute( 'tabindex', '0' );
		}

		// Hide all tab panels except the first
		this.tabPanels.forEach( ( panel, index ) => {
			if ( index !== 0 ) {
				panel.setAttribute( 'hidden', true );
			}
		} );
	}

	/**
	 * Removes 'tab-' prefix from a string
	 * @example 'tab-foo' -> '#foo'
	 */
	getTabHashFromId( id ) {
		return `#${ id.replace( 'tab-', '' ) }`;
	}

	/**
	 * Programmatically clicks a tab depending on the URL hash
	 */
	handleHash() {
		const { hash } = window.location;

		if ( hash ) {
			setTimeout( () => {
				const tabEl = this.tabMap[ hash ];
				if ( tabEl ) {
					tabEl.click();
				}
			}, 0 );
		}
	}

	/**
	 * Handle keyboard navigation with arrows
	 */
	handleKeydown( e ) {
		const key = e.key;
		const target = e.target;
		const tabs = Array.from( this.tabList.querySelectorAll( '.tabs__nav-item' ) );
		let index = tabs.indexOf( target );

		switch ( key ) {
			case 'ArrowLeft':
				if ( this.orientation === 'horizontal' ) {
					e.preventDefault();
					index = index - 1 < 0 ? tabs.length - 1 : index - 1;
				}
				break;
			case 'ArrowUp':
				if ( this.orientation === 'vertical' ) {
					e.preventDefault();
					index = index - 1 < 0 ? tabs.length - 1 : index - 1;
				}
				break;
			case 'ArrowRight':
				if ( this.orientation === 'horizontal' ) {
					e.preventDefault();
					index = ( index + 1 ) % tabs.length;
				}
				break;
			case 'ArrowDown':
				if ( this.orientation === 'vertical' ) {
					e.preventDefault();
					index = ( index + 1 ) % tabs.length;
				}
				break;
			default:
				return;
		}

		// Focus the new tab
		tabs[ index ].focus();

		// Select the new tab
		this.changeTabs( { currentTarget: tabs[ index ] } );
	}

	/**
	 * Handle changing tabs when clicking on a tab
	 */
	changeTabs( e ) {
		const target = e.currentTarget;

		if ( target.classList.contains( this.config.navItemClass ) ) {
			// Unselect all tabs
			Array.from( this.tabList.querySelectorAll( '[aria-selected="true"]' ) ).forEach( ( tab ) => {
				tab.setAttribute( 'aria-selected', false );
				tab.setAttribute( 'tabindex', '-1' );
				tab.classList.remove( this.config.navItemActiveClass );
			} );

			// Set this tab as selected
			target.setAttribute( 'aria-selected', true );
			target.setAttribute( 'tabindex', '0' );
			target.classList.add( this.config.navItemActiveClass );

			// Hide all tab panels
			this.tabPanels.forEach( ( panel ) => panel.setAttribute( 'hidden', true ) );

			// Show the selected panel
			this.node.querySelector( `#${ target.getAttribute( 'aria-controls' ) }` ).removeAttribute( 'hidden' );

			// Change URL hash based on selected tab
			if ( window.history && history.pushState ) {
				const hash = this.getTabHashFromId( target.id );
				history.replaceState( {}, '', hash );
			}

			// Update the current item text on mobile
			if ( this.currentItemText ) {
				this.currentItemText.innerText = target.innerText.trim();
			}
		}
	}
}

Tabs.instances = Array.from( document.querySelectorAll( '.tabs' ) ).map( ( n ) => new Tabs( n ) );
