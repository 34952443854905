/**
 * Get the value of a specific cookie by name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} - The value of the cookie if found, otherwise null.
 */
const getCookieValue = ( name ) => {
	const match = document.cookie.match( new RegExp( `(^| )${name}=([^;]+)` ) );
	if ( match ) return match[2];
	return null;
};

const jsonToUrlEncoded = ( json ) => Object.keys( json )
	.map( key => `${encodeURIComponent( key )}=${encodeURIComponent( json[key] )}` )
	.join( '&' );

/**
 * Perform an action before a Gravity Form is submitted.
 *
 * @param {number} formId The ID of the Gravity Form.
 */
const handleFormPreSubmission = () => {
	const form = document.querySelector( '.gform_wrapper form' );
	const inputField = form.querySelector( '[name="input_9999"]' );
	if ( inputField && !inputField.value ) {
		const cuvid = getCookieValue( 'cuvid' );

		if ( cuvid ) {
			inputField.value = jsonToUrlEncoded( { cuvid } );
		}
	}
};

const handleSendCalcValues = () => {
	const form = document.querySelector( 'dialog form' );
	const { formid } = form.dataset;
	const calcValuesElement = document.querySelector( '.calculator-result-packaged' );
	const cuvid = getCookieValue( 'cuvid' );
	const input = form.querySelector( `#field_${formid}_9999 input` );
	let values = {};

	if ( calcValuesElement ) {
		const calculatorValues = JSON.parse( calcValuesElement.innerText );
		values = { ...calculatorValues };
	}

	if ( cuvid ) {
		const cuvidCookie = { cuvid };
		values = { ...values, ...cuvidCookie };
	}

	input.value = jsonToUrlEncoded( values );
};

document.addEventListener( 'DOMContentLoaded', () => {
	const targetNode = document.body;
	const observerOptions = {
		childList: true,
		subtree: true,
	};

	const attachEventListener = () => {
		const dialogTrigger = document.querySelector( '.dialog-trigger' );
		if ( dialogTrigger ) {
			dialogTrigger.addEventListener( 'click', handleSendCalcValues );
		}
	};

	const observer = new MutationObserver( ( mutationsList, observer ) => {
		attachEventListener();
	} );

	observer.observe( targetNode, observerOptions );

	// Attach event listener initially
	attachEventListener();
} );

window.addEventListener( 'load', handleFormPreSubmission );
