export class MediaSlider {
	/**
	 * Initialize a new Sections element
	 *
	 * @param {HTMLElement} node - the node that contains the root scrolling element
	 * @param {object} opts -
	 */
	constructor( node, opts ) {
		this.node = node;
		this.opts = {
			back: '.media-slider__back',
			forward: '.media-slider__forward',
			content: '.media-slider__slides',
			items: '.media-slider__slides > *',
			slides: '.dot',
			...opts,
		};

		if ( ! this.node ) {
			return;
		}

		this.interval = null;
		this.back = this.node.querySelector( this.opts.back );
		this.forward = this.node.querySelector( this.opts.forward );
		this.content = this.node.querySelector( this.opts.content );
		this.items = this.node.querySelectorAll( this.opts.items );
		this.slides = this.node.querySelectorAll( this.opts.slides );
 
		window.addEventListener( 'resize', this.setState.bind( this ) );
		this.content.addEventListener( 'scroll', this.setState.bind( this ) );

		if ( this.back ) {
			this.back.addEventListener( 'click', this.backIndex.bind( this ) );
		}

		if ( this.forward ) {
			this.forward.addEventListener( 'click', this.forwardIndex.bind( this ) );
		}

		if( this.slides ){
			var vm = this;
			for(var i = 0; i < this.slides.length; i++){
				this.slides[i].addEventListener( 'click',this.navigateIndex.bind( this ) );
			}
		}

		this.setIndex( 0 );
		this.setState();
	}
	
	/**
	 * Scroll forward the scrollable content area
	 */
	scrollForward() {
		this.scrollInterval = setInterval( () => {
			this.content.scrollTo( { left: this.content.scrollLeft + 5 } );

			if ( this.forward.disabled ) {
				this.scrollStop();
			}
		}, 10 );
	}

	/**
	 * Scroll backwards the scrollable content area
	 */
	scrollBack() {
		this.scrollInterval = setInterval( () => {
			this.content.scrollTo( { left: Math.max( 0, this.content.scrollLeft - 5 ) } );

			if ( this.back.disabled ) {
				this.scrollStop();
			}
		}, 10 );
	}

	/**
	 * Stop the scrolling interval if it's active
	 */
	scrollStop() {
		if ( typeof this.scrollInterval === 'number' ) {
			clearInterval( this.scrollInterval );
		}

		this.scrollInterval = null;
	}

	setState() {
		const firstItem = this.items[ 0 ];
		const lastItem = this.items[ this.items.length - 1 ];

		if ( this.back ) {
			if ( firstItem.offsetLeft - this.content.scrollLeft >= 0 ) {
				// first item is is view
				this.back.disabled = true;
			} else {
				// first item is outside the scroll area
				this.back.removeAttribute( 'disabled' );
			}
		}

		if ( this.forward ) {
			if ( this.content.scrollLeft + this.content.offsetWidth + 80 >= lastItem.offsetLeft + lastItem.offsetWidth - 1) {
				// the last item is fully in view
				this.forward.disabled = true;
				// this.forward.setAttribute( 'disabled',true );
			} else {
				// last item is outside the scroll area
				this.forward.removeAttribute( 'disabled' );
			}
		}
	}

	getIndex() {
		const offset = this.getPaddingOffset();
		const distances = [ ...this.items ].map( ( item ) => Math.abs( item.offsetLeft - this.content.scrollLeft - offset ) );

		return distances.indexOf( Math.min( ...distances ) );
	}

	getPaddingOffset() {
		return Math.max( parseInt( getComputedStyle( this.content ).paddingLeft, 10 ), 0 );
	}

	setIndex( index, behavior = 'smooth' ) {
		if ( index <= this.items.length ) {
			const left = this.items[ index ].offsetLeft - this.getPaddingOffset();

			this.content.scrollTo( {
				left: Math.max( 0, left ),
				behavior,
			} );
		} else if ( interval > 0 ) {
			clearInterval( interval );
			interval = null;
		}
		for(var i = 0; i < this.slides.length; i++){
			if(this.slides[i].getAttribute('data-slide') == (index + 1))
				this.slides[i].classList.add('isActiveSlide');
			else
				this.slides[i].classList.remove('isActiveSlide');		
		}
	}

	backIndex() {
		this.pauseVideos();
		this.setIndex( Math.round( Math.max( this.getIndex() - 1, 0 ) ) );
	}

	forwardIndex() {
		this.pauseVideos();
		this.setIndex( Math.round( Math.min( this.getIndex() + 1, this.items.length - 1 ) ) );
	}

	navigateIndex(slide){
		var i = slide.target.getAttribute('data-slide') - 1;
		this.setIndex(i);
	}
	/**
	 * Pause the video if slider button is clicked
	 */
	pauseVideos() {

		const videoBlocks = this.node.querySelectorAll('.wp-block-wdg-video-block.wdg-video-block');
		videoBlocks.forEach(videoBlock => {
			const playButton = videoBlock.querySelector('.wdg-video-block__buttons--play');
			const video = videoBlock.querySelector('video');
			if (video && !video.paused) {
                video.pause();
                playButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none"><circle cx="26" cy="26.4639" r="25" stroke="#F1B434" stroke-width="2"/><path d="M34 25.9625L22.5698 32.9875L22.5698 18.9375L34 25.9625Z" fill="white"/></svg> Play Video';
            }
		})
	}
}

document.addEventListener( 'DOMContentLoaded', () => {
	document.querySelectorAll( '.media-slider' ).forEach( ( node ) => {
		console.log( new MediaSlider( node, { ...node.dataset } ) );
	} );
} );
