document.addEventListener('DOMContentLoaded', () => {
	// Query the video and button elements.
	const videoBlocks = document.querySelectorAll('.wp-block-wdg-video-block.wdg-video-block');
    videoBlocks.forEach(videoBlock => {
        if (!videoBlock) return; // Exit if the video block is not found.

        const video = videoBlock.querySelector('video');
        const soundButton = videoBlock.querySelector('.wdg-video-block__buttons--sound');
        const playButton = videoBlock.querySelector('.wdg-video-block__buttons--play');

        if(playButton.textContent.indexOf('Pause Video') > -1)
            playButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none"><circle cx="26" cy="26.3555" r="25" stroke="#F1B434" stroke-width="2"/><rect x="19.125" y="16.25" width="4.75" height="20.2109" fill="white"/><rect x="28.125" y="16.25" width="4.75" height="20.2109" fill="white"/></svg> Pause Video';
        else
            playButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none"><circle cx="26" cy="26.4639" r="25" stroke="#F1B434" stroke-width="2"/><path d="M34 25.9625L22.5698 32.9875L22.5698 18.9375L34 25.9625Z" fill="white"/></svg> Play Video';

        // Toggle play/pause on clicking the play button.
        playButton.addEventListener('click', function () {
            if (video.paused || video.ended) {
                video.play();
                playButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none"><circle cx="26" cy="26.3555" r="25" stroke="#F1B434" stroke-width="2"/><rect x="19.125" y="15.25" width="4.75" height="20.2109" fill="white"/><rect x="28.125" y="15.25" width="4.75" height="20.2109" fill="white"/></svg> Pause Video';
            } else {
                video.pause();
                playButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none"><circle cx="26" cy="26.4639" r="25" stroke="#F1B434" stroke-width="2"/><path d="M34 25.9625L22.5698 32.9875L22.5698 18.9375L34 25.9625Z" fill="white"/></svg> Play Video';
            }
        });

        // Toggle mute/unmute on clicking the sound button.
        soundButton.addEventListener('click', function () {
            video.muted = !video.muted;
            this.textContent = video.muted ? 'Unmute' : 'Mute'; // Change button text accordingly

            if (video.muted) {
                this.classList.remove('not-muted');
            } else {
                this.classList.add('not-muted');
            }
        });
    });
});
