document.addEventListener('DOMContentLoaded', function() {
    var tocList = document.getElementById('tocList');
    var toggleButton = document.getElementById('toggleButton');

    if (toggleButton)
    toggleButton.addEventListener('click', function() {
        tocList.classList.toggle('wdg-toc-list');
        if (tocList.classList.contains('wdg-anchor-links')) {
            addCloseButton();
        } else {
            removeCloseButton();
        }
    });
    function addCloseButton() {

        var closeButtonHtml = '<div class="wp-block-button" id="closeButton"></div>';
        tocList.insertAdjacentHTML('afterbegin', closeButtonHtml);
        var closeButton = document.getElementById('closeButton');
        closeButton.addEventListener('click', function() {
            tocList.classList.add('wdg-toc-list');
            removeCloseButton();
        });
    }
    function removeCloseButton() {
        var closeButton = document.getElementById('closeButton');
        if (closeButton) {
            closeButton.parentNode.removeChild(closeButton);
        }
    }
});