// Check if the next adjacent div has the class "has-background"
const hasBackgroundDiv = document.querySelector('.hero-wrapper + .has-background') || document.querySelector('.no-hero-wrapper + .has-background');
const heroWrapper = document.querySelector('.hero-wrapper');
const noHeroWrapper = document.querySelector('.no-hero-wrapper');
// remove margin-bottom from the default page header with class "no-hero-wrapper" or hero-wrapper
if (hasBackgroundDiv && (heroWrapper || noHeroWrapper)) {
    if (heroWrapper) {
        heroWrapper.style.marginBottom = '0';
    }
    if (noHeroWrapper) {
        noHeroWrapper.style.marginBottom = '0';
    }
}